<template>
  <div class="centered-container">

    <md-content class="md-elevation-3">

      <div class="title">
        <img src="@/assets/logo-arene.png" alt="logo">
        <div class="md-title">Réinitialisation du mot de passe</div>
      </div>

      <validator v-slot="{ handleSubmit }">
        <form novalidate @submit.prevent="handleSubmit(sendResetLink)">
          <div class="form">
            <rule name="e-mail" rules="required|email" v-slot="{errors}">
              <md-field>
                <label>E-mail</label>
                <md-input v-model="email"></md-input>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>
          </div>

          <div class="actions md-layout md-alignment-center-space-between">
            <router-link class="link" to="login">
              Revenir à la page de connexion
            </router-link>
            <md-button type="submit" class="md-raised md-primary site-color">Envoyer</md-button>
          </div>
        </form>
      </validator>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
      </div>

    </md-content>
    <div class="background"/>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import notificationMixin from '@/mixins/notification-mixin';
import AuthService from '@/components/auth/AuthService';

extend('email', {
  ...email,
  message: messages.email,
});

extend('required', {
  ...required,
  message: messages.required,
});

export default {
  name: 'LoginForm',
  data: () => ({
    loading: false,
    email: '',
  }),
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
  mixins: [notificationMixin],
  methods: {
    async sendResetLink() {
      this.loading = true;
      try {
        const response = await AuthService.sendResetLink(this.email);
        if (response.status === 200) {
          this.showSuccess('Un lien de recuperation de mot de passe a été envoyé à votre adresse mail');
        } else {
          this.showError(response.data);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
